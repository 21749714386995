import React from "react";
import "../assets/ServicesBox.css";

const ServicesBox = () => {
  return (
    <>
      <div className="services-box">
        <div className="service-box box-purple">
          <header className="box-header">
            <img
              src="https://images.pexels.com/photos/1185617/pexels-photo-1185617.jpeg?auto=compress&cs=tinysrgb&w=1600"
              alt=""
            />
            <div>
              <h3>Events Photography</h3>
              <p>Professional</p>
            </div>
          </header>
          <p className="box-content">
            Lorem ipsum dolor sit amet consectetur, adipisicing elit. Facere
            harum iste, pariatur nostrum voluptatem explicabo eos cumque saepe
            rerum. Laudantium aut ab beatae dolor deleniti?
          </p>
          <p className="box-quote">
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Mollitia
            laudantium libero deserunt accusamus sequi, nam veniam iusto illo
            laborum ullam consequuntur blanditiis nulla, corrupti reiciendis
            molestias quod iste voluptatem reprehenderit eaque ut. Nam,
            repellendus animi!" <br />
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Mollitia
            laudantium libero deserunt accusamus sequi, nam veniam iusto illo
            laborum ullam consequuntur blanditiis nulla, corrupti reiciendis
            molestias quod iste voluptatem reprehenderit eaque ut. Nam,
            repellendus animi!"
          </p>
        </div>
        <div className="service-box">
          <header className="box-header">
            <img
              src="https://images.pexels.com/photos/66134/pexels-photo-66134.jpeg?auto=compress&cs=tinysrgb&w=1600"
              alt=""
            />
            <div>
              <h3>Film Shooting Photography</h3>
              <p>Professional</p>
            </div>
          </header>
          {/* <p className="box-content">
            Lorem ipsum dolor sit amet consectetur, adipisicing elit. Facere
            harum iste, pariatur nostrum voluptatem explicabo eos cumque saepe
            rerum. Laudantium aut ab beatae dolor deleniti?
          </p> */}
          <p className="box-quote">
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Mollitia
            laudantium libero deserunt accusamus sequi, nam veniam iusto illo
            laborum ullam consequuntur blanditiis nulla, corrupti reiciendis
            molestias quod iste voluptatem reprehenderit eaque ut. Nam,
            repellendus animi!"
          </p>
        </div>
        <div className="service-box">
          <header className="box-header">
            <img
              src="https://images.pexels.com/photos/2102765/pexels-photo-2102765.jpeg?auto=compress&cs=tinysrgb&w=1600"
              alt=""
            />
            <div>
              <h3>Special Occasion Photography</h3>
              <p>Professional</p>
            </div>
          </header>
          {/* <p className="box-content">
            Lorem ipsum dolor sit amet consectetur, adipisicing elit. Facere
            harum iste, pariatur nostrum voluptatem explicabo eos cumque saepe
            rerum. Laudantium aut ab beatae dolor deleniti?
          </p> */}
          <p className="box-quote">
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Mollitia
            laudantium libero deserunt accusamus sequi, nam veniam iusto illo
            laborum ullam consequuntur blanditiis nulla, corrupti reiciendis
            molestias quod iste voluptatem reprehenderit eaque ut. Nam,
            repellendus animi!"
          </p>
        </div>
        <div className="service-box">
          <header className="box-header">
            <img
              src="https://biosash.com/wp-content/uploads/2021/01/Biosash-Emerging-Awards.jpg"
              alt=""
            />
            <div>
              <h3>Award Winning Photography</h3>
              <p>Professional</p>
            </div>
          </header>
          <p className="box-content">
            Lorem ipsum dolor sit amet consectetur, adipisicing elit. Facere
            harum iste, pariatur nostrum voluptatem explicabo eos cumque saepe
            rerum. Laudantium aut ab beatae dolor deleniti?
          </p>
          <p className="box-quote">
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Mollitia
            laudantium libero deserunt accusamus sequi, nam veniam iusto illo
            laborum ullam consequuntur blanditiis nulla, corrupti reiciendis
            molestias quod iste voluptatem reprehenderit eaque ut. Nam,
            repellendus animi!"
          </p>
        </div>
        <div className="service-box">
          <header className="box-header">
            <img
              src="https://images.pexels.com/photos/1229414/pexels-photo-1229414.jpeg?auto=compress&cs=tinysrgb&w=1600"
              alt=""
            />
            <div>
              <h3>Fashion Photography</h3>
              <p>Professional</p>
            </div>
          </header>
          <p className="box-content">
            Lorem ipsum dolor sit amet consectetur, adipisicing elit. Facere
            harum iste, pariatur nostrum voluptatem explicabo eos cumque saepe
            rerum. Laudantium aut ab beatae dolor deleniti? <br />
          </p>
          <p className="box-quote">
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Mollitia
            laudantium libero deserunt accusamus sequi, nam veniam iusto illo
            laborum ullam consequuntur blanditiis nulla, corrupti reiciendis
            molestias quod iste voluptatem reprehenderit eaque ut. Nam,
            repellendus animi!" <br />
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Mollitia
            laudantium libero deserunt accusamus sequi, nam veniam iusto illo
            laborum ullam consequuntur blanditiis nulla, corrupti reiciendis
            molestias quod iste voluptatem reprehenderit eaque ut. Nam,
            repellendus animi!"
          </p>
        </div>
      </div>
    </>
  );
};

export default ServicesBox;
