import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { useParams } from "react-router-dom";
import "../assets/Blogs.css";

const Blogs = () => {
  const { id } = useParams();

  const blogs = [
    {
      id: 1,
      title: "Events Photography",
      desc: "This is blog first album contents. A website where a person writes regularly about topics that interest them, usually with photographs and links to other websites they find interesting. Photography is the art, application, and practice of creating images by recording light, either electronically by means of an image sensor, or chemically by means of a light-sensitive material such as photographic film. It is employed in many fields of science, manufacturing (e.g., photolithography), and business, as well as its more direct uses for art, film and video production, recreational purposes, hobby, and mass communication.",
      coverImg:
        "https://images.pexels.com/photos/1185617/pexels-photo-1185617.jpeg?auto=compress&cs=tinysrgb&w=1600",
    },
    {
      id: 2,
      title: "Film Shooting Photography",
      desc: "This is blog second album contents. Many wedding photographers still rely on their SLR camera. There are various reasons for this. Firstly, DSLRs have a good size that fits some photographer's hands better than a compact Sony Alpha 7R III",
      coverImg:
        "https://images.pexels.com/photos/66134/pexels-photo-66134.jpeg?auto=compress&cs=tinysrgb&w=1600",
    },
    {
      id: 3,
      title: "Special Occasion Photography",
      desc: "A blog is an informational website consisting of discrete, often informal diary-style text entries. Posts are typically displayed in reverse chronological order so that the most recent post appears first, at the top of the web page.",
      coverImg:
        "https://images.pexels.com/photos/2102765/pexels-photo-2102765.jpeg?auto=compress&cs=tinysrgb&w=1600",
    },
    {
      id: 4,
      title: "Award Winning Photography",
      desc: "This is blog fourth album contents. The earliest instance of a commercial blog was on the first business to consumer Web site created in 1995 by Ty, Inc., which featured a blog in a section called. The entries were maintained by featured Beanie Babies that were voted for monthly by Web site visitors.",
      coverImg:
        "https://biosash.com/wp-content/uploads/2021/01/Biosash-Emerging-Awards.jpg",
    },
    {
      id: 5,
      title: "Fashion Photography",
      desc: "Fashion photography is a combination of product, portrait and fine art photography, where art meets commerce. It's the clothes, models, poses, lighting, backgrounds and more – all working together to create beautiful imagery to sell an aesthetic and lifestyle.",
      desc1:
        "Fashion photography is a genre of photography that portrays clothing and other fashion items. This sometimes includes haute couture garments. It typically consists of a fashion photographer taking pictures of a dressed model in a photographic studio or an outside setting.",
      coverImg:
        "https://images.pexels.com/photos/1229414/pexels-photo-1229414.jpeg?auto=compress&cs=tinysrgb&w=1600",
    },
  ];

  let blog = blogs.filter((blog) => blog.id == id);
  blog = blog[0];
  console.log(blog);
  return (
    <>
      <Navbar />
      <div className="blogs-section">
        <div className="blogs">
          <div className="blogs-container">
            <div className="blogs-content shadow-md bg-[#f3f3f3]">
              <img className="w-[100%]" src={blog.coverImg} alt="" />
              <div className="blogs-content py-6 px-3">
                <h3 className="text-center font-bold text-3xl text-[#7e7e7e] pb-4">
                  {blog.title}
                </h3>
                <p className="text-center">{blog.desc} </p> <br />
                <p className="text-center">{blog.desc1} </p>
              </div>
            </div>
          </div>
        </div>
        <div className="blog-sidepanel">
          <div className="blog-category">
            <h3 className="text-3xl font-semibold mb-8 text-[#777777] border-l-4 pl-3">
              Blog Category
            </h3>
            <div className="blog-cat-1">
              <img
                src="https://img.freepik.com/free-photo/studio-close-up-portrait-young-fresh-blonde-woman-brown-straw-poncho-wool-black-trendy-hat-round-glasses-looking-camera-green-leather-had-bag_273443-1121.jpg?w=1380&t=st=1701446544~exp=1701447144~hmac=6f45a43c8dd24427f6c4b6c7bc9f12f72d421e77b50b0a5421caad09abe91b50"
                alt=""
              />
              <h3 className="blog-cat-title font-semibold text-[18px]">
                Fashion designer
              </h3>
              <span className="blog-cat-post">61 posts</span>
            </div>
            <div className="blog-cat-1">
              <img
                src="https://img.freepik.com/free-photo/friends-celebrating-birthday-indoors_52683-98194.jpg?w=1380&t=st=1701448315~exp=1701448915~hmac=82ed8d355b330020c457a05b6d8d7a24269f0a62d355d424210a336ac0f21505"
                alt=""
              />
              <h3 className="blog-cat-title font-semibold text-[18px]">
                Event Photography
              </h3>
              <span className="blog-cat-post">72 posts</span>
            </div>
            <div className="blog-cat-1">
              <img
                src="https://images.pexels.com/photos/11762791/pexels-photo-11762791.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                alt=""
              />
              <h3 className="blog-cat-title font-semibold text-[18px]">
                Wedding Events
              </h3>
              <span className="blog-cat-post">108 posts</span>
            </div>
            <div className="blog-cat-1">
              <img
                src="https://images.pexels.com/photos/3125846/pexels-photo-3125846.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                alt=""
              />
              <h3 className="blog-cat-title font-semibold text-[18px]">
                Models Photography
              </h3>
              <span className="blog-cat-post">96 posts</span>
            </div>
            <div className="blog-cat-1">
              <img
                src="https://img.freepik.com/free-photo/group-friends-making-beach-party_23-2147643668.jpg?w=1380&t=st=1701448633~exp=1701449233~hmac=72c9041bbdfe132d4d76488a5ed936a8e607d18e1f3dd79745060948d571ea9c"
                alt=""
              />
              <h3 className="blog-cat-title font-semibold text-[18px]">
                Occasion Events
              </h3>
              <span className="blog-cat-post">87 posts</span>
            </div>
          </div>
          <div className="blog-gallery">
            <h3 className="text-3xl font-semibold mb-8 text-[#777777] border-l-4 pl-3">
              Trend's Photo
            </h3>
            <div className="blog-gallery-section">
              <img
                src="https://img.freepik.com/free-photo/young-beautiful-woman-casual-outfit-isolated-studio_1303-20526.jpg?w=1380&t=st=1701447397~exp=1701447997~hmac=1fce13c8130552a8e08d9b95e62b3597a32ae5b9a24c089086de48afcd65abd4"
                alt=""
              />
              <img
                src="https://img.freepik.com/free-photo/young-woman-beautiful-dress-hat_1303-17517.jpg?w=1380&t=st=1701447432~exp=1701448032~hmac=10286b32d94395c4daada7427421094d97379ab612da9c80ebfa2e0d6ff49c3a"
                alt=""
              />
              <img
                src="https://img.freepik.com/free-photo/emotional-lady-with-short-curle-hair-funny-dancing-sunglasses-fur-coat_197531-7115.jpg?w=1380&t=st=1701447442~exp=1701448042~hmac=0b88d0278710726bfc29e12bd76f12280a6352f26423ad9ca26acc8142df242a"
                alt=""
              />
              <img
                src="https://img.freepik.com/free-photo/graceful-woman-green-pants-standing-with-hands-pockets-studio-shot-ginger-girl-isolated-yellow-space_197531-15214.jpg?w=1380&t=st=1701447495~exp=1701448095~hmac=5f469fd2c0ee97b7c83659a9e4f0ee076dc0281aeb24524f86afd5f1bbbfba76"
                alt=""
              />
              <img
                src="https://img.freepik.com/free-photo/charming-curly-red-haired-woman-white-top-makes-bubble-gum-portrait-young-woman-orange-background_197531-14858.jpg?w=1380&t=st=1701447569~exp=1701448169~hmac=6c5151d97166cb671f4aa5055a7b18fe2afb9d5d7c460c15966dca4ce86fc325"
                alt=""
              />
              <img
                src="https://img.freepik.com/free-photo/wonderful-european-girl-dancing-with-inspired-smile-indoor-portrait-attractive-woman-long-blue-skirt_197531-5250.jpg?w=1380&t=st=1701447609~exp=1701448209~hmac=1e5025cbff632fca09bec4daf97486aa39a65b1687ae2b5c42cabb517cea6db3"
                alt=""
              />
              <img
                src="https://img.freepik.com/free-photo/young-beautiful-business-woman-isolated-studio_1303-20563.jpg?w=1380&t=st=1701447614~exp=1701448214~hmac=3605724f6648b850304dd76f5962db4e28b421423b691d322fde6484dd0a3d24"
                alt=""
              />
              <img
                src="https://img.freepik.com/free-photo/positive-female-tourist-with-photo-camera-travel-backpack_613910-7126.jpg?w=1380&t=st=1701447636~exp=1701448236~hmac=0f5c63773e725ab1c83f07b3db306c083b211264dab19fae4671dc79dee9aeb0"
                alt=""
              />
              <img
                src="https://img.freepik.com/free-photo/young-teen-woman-sunglasses-hat-holding-shopping-bags-her-hands-feeling-so-happiness-isolated-green-wall_231208-2681.jpg?w=1380&t=st=1701447664~exp=1701448264~hmac=20fb790e0dfde9b019f9688a5cb06b4b1c7a6b314227a90056a4b9321642cfbf"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Blogs;
