import React from "react";
import {
  FaEnvelope,
  FaFacebook,
  FaGlobe,
  FaInstagram,
  FaPhone,
  FaTwitter,
  FaWhatsapp,
  FaYoutube,
} from "react-icons/fa";
import { CgWebsite } from "react-icons/cg";
import { FaLocationDot } from "react-icons/fa6";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <>
      <section className="footer">
        <div className="body-container footer-section">
          <div>
            <h3>About us</h3>
            <p>
              Photography is about capturing the beauty of life moment. we are a
              very reliable company to conduct wedding family shoots, Candid
              Photography, Traditional Photography, Couple photography & Pre
              Wedding shoot in Gaya.
            </p>
            <ul className="social-links">
              <li>
                <Link
                  style={{ cursor: "pointer" }}
                  target="_blank"
                  to="https://instagram.com/"
                >
                  <FaInstagram className="social-icon" />
                </Link>
              </li>
              <li>
                <Link
                  style={{ cursor: "pointer" }}
                  target="_blank"
                  to="https://facebook.com/"
                >
                  <FaFacebook className="social-icon" />
                  {/* <FaFacebookF className="social-icon" /> */}
                  {/* <FaFacebookSquare className="social-icon" /> */}
                </Link>
              </li>
              <li>
                <Link
                  style={{ cursor: "pointer" }}
                  target="_blank"
                  to="https://web.whatsapp.com/"
                >
                  <FaWhatsapp className="social-icon" />
                </Link>
              </li>
              <li>
                <Link
                  style={{ cursor: "pointer" }}
                  target="_blank"
                  to="https://youtube.com/"
                >
                  <FaYoutube className="social-icon" />
                </Link>
              </li>
              <li>
                <Link
                  style={{ cursor: "pointer" }}
                  target="_blank"
                  to="https://twitter.com/"
                >
                  <FaTwitter className="social-icon" />
                </Link>
              </li>
            </ul>
          </div>
          <div>
            <h3>Galleries</h3>
            <ul>
              <li>
                <Link to="/portfolio">Events Photography</Link>
              </li>
              <li>
                <Link to="/portfolio">Film Shooting Photography</Link>
              </li>
              <li>
                <Link to="/portfolio">Special Occasion Photography</Link>
              </li>
              <li>
                <Link to="/portfolio">Award Winning Photography</Link>
              </li>
              <li>
                <Link to="/portfolio">Fashion Photography</Link>
              </li>
            </ul>
          </div>
          <div>
            <h3>Videos</h3>
            <ul>
              <li>
                <Link to="#">Wedding</Link>
              </li>
              <li>
                <Link to="#">Couple Portraits</Link>
              </li>
              <li>
                <Link to="#">Candid Moments</Link>
              </li>
              <li>
                <Link to="#">Outdoor Wedding</Link>
              </li>
              <li>
                <Link to="#">Short Stories</Link>
              </li>
            </ul>
          </div>
          <div className="footer-location">
            <h3>Location</h3>
            <p>
              <FaLocationDot
                style={{ marginTop: "-60px" }}
                className="info-icon"
              />{" "}
              Manpur Bajar Shivcharan lane, <br /> po+ps - buniyadganj gaya
              (Bihar) <br />
              pincode-823003
            </p>
            <p>
              <FaPhone className="info-icon" /> 8873700176
            </p>
            <p>
              <FaEnvelope className="info-icon" /> info@abhinayak.com
            </p>
            <p>
              <FaGlobe className="info-icon" />{" "}
              <Link
                className="text-[#37d1b7] hover:text-[#ee594d] font-semibold pl-1"
                to="https://skhubs.in"
                target="_blank"
              >
                skhubs.in
              </Link>
            </p>
          </div>
        </div>
      </section>
      <section className="footer-bottom">
        <p className="text-center">
          Copyright 2023 &copy; Abhiphotography | Developed by:
          <Link
            className="text-[#37d1b7] hover:text-[#ee594d] font-semibold pl-1"
            to="https://skhubs.in"
            target="_blank"
          >
            SKHUBS.IN
          </Link>
        </p>
      </section>
    </>
  );
};

export default Footer;
