import React from "react";
import "./Components.css";
import Typed from "react-typed";

const Hero = () => {
  return (
    <section className="hero-bg ">
      <div className="container text-white">
        <div className="max-w-[800px] w-full h-screen mx-auto text-center flex flex-col justify-center items-start px-10">
          <p className="text-[#00df9a] text-[30px] p-2 text-left">
            HI, I’m Abhi Nayak
          </p>
          <h1 className="md:text-7xl sm:text-6xl text-4xl text-left font-bold md:py-6">
            {/* Beautiful Moment of Album */}
            Capture Moment Never Fade
          </h1>
          <div className="flex justify-center items-center mb-6">
            <p className="md:text-5xl sm:text-4xl text-xl font-bold">I'm</p>
            <Typed
              className="md:text-5xl sm:text-4xl text-xl font-bold pl-2"
              strings={["Photography", "Videography", "Decoration"]}
              typeSpeed={120}
              backSpeed={140}
              loop
            />
          </div>
          <p className="md:text-2xl text-left">
            Photography is about capturing the beauty of life moment, the
            emotions, and the memories that we can forever hold.
          </p>
        </div>
      </div>
    </section>
  );
};

export default Hero;
