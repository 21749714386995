import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import GallerySection from "../components/GallerySection";
import "./routes.css";

const Gallery = () => {
  return (
    <>
      <Navbar />

      <GallerySection />
      <br />
      <Footer />
    </>
  );
};

export default Gallery;
