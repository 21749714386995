import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import "../routes/routes.css";
// import { useState } from "react";
import Teams from "../components/Teams";
import { Link } from "react-router-dom";
import abhiImg from "../assets/images/abhi.JPG";

const About = () => {
  // const [tab, setTab] = useState(1);
  // const [toggle, setToggle] = useState(1);

  // function updateToggle(id) {
  //   setToggle(id);
  //   setTab(id);
  // }

  return (
    <div>
      <Navbar />
      <div className="about-section">
        <div className="about-container">
          <div className="about-left">
            <h3 className="text-3xl sm:text-5xl md:text-6xl  font-semibold text-gray-500">
              We Live For Passion
            </h3>

            <p>
              We specialize in all kinds of photography & Videography with a
              hint of modernity. We love to take pictures of people & we are
              very friendly with them. Our goal is to show the beauty, grace,
              strength and enthusiasm of people.
            </p>
            <p>
              We always shoot in RAW and in manual mode. All of our
              post-processing is done in both Adobe Lightroom and Adobe
              Photoshop. As an artist, the biggest favor we ever did ourself was
              stop comparing our work to that of others, and rather than trying
              to mimic the editing style of someone else-creating color
              combinations that combined all our favorite things into one custom
              look. A mixture of rich, hazy, matte and muted.
            </p>
            <Link to="/contact" className="about-btn">
              Contact Us
            </Link>
          </div>
          <div className="about-right">
            <img src={abhiImg} alt="" />
          </div>
        </div>
      </div>
      <Teams />
      {/* <div className="tabs-section">
        <div className="tabs-container">
          <ul className="tabs">
            <li
              className={tab === 1 ? "active-color" : ""}
              onClick={() => updateToggle(1)}
            >
              html
            </li>
            <li
              className={tab === 2 ? "active-color" : ""}
              onClick={() => updateToggle(2)}
            >
              css
            </li>
            <li
              className={tab === 3 ? "active-color" : ""}
              onClick={() => updateToggle(3)}
            >
              javascript
            </li>
          </ul>
          <div className={toggle === 1 ? "show-content" : "tabs-content"}>
            <h1>html</h1>
            <p>
              html Lorem, ipsum dolor sit amet consectetur adipisicing elit.
              Doloremque ullam consequatur minima rerum numquam repellendus.
            </p>
          </div>
          <div className={toggle === 2 ? "show-content" : "tabs-content"}>
            <h1>css</h1>
            <p>
              css Lorem, ipsum dolor sit amet consectetur adipisicing elit.
              Doloremque ullam consequatur minima rerum numquam repellendus.
            </p>
          </div>
          <div className={toggle === 3 ? "show-content" : "tabs-content"}>
            <h1>javascript</h1>
            <p>
              javascript Lorem, ipsum dolor sit amet consectetur adipisicing
              elit. Doloremque ullam consequatur minima rerum numquam
              repellendus.
            </p>
          </div>
        </div>
      </div> */}
      <Footer />
    </div>
  );
};

export default About;
