import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { FaBars, FaTimes } from "react-icons/fa";
import "./Components.css";
import abhiLogo from "../assets/images/Abhi-logo.png";

const Navbar = () => {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);

  const [scrolled, setScrolled] = useState(false);

  function handleScroll() {
    const offset = window.scrollY;
    if (offset > 60) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  }

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
  }, []);

  return (
    <div className={`header ${scrolled ? "sticky-header" : ""}`}>
      <NavLink to="/">
        <div className="brandSpace">
          {/* <h1 className="text-3xl text-red-500 font-bold">Abhi Photography</h1> */}
          <img style={{ width: "200px" }} src={abhiLogo} alt="" />
        </div>
      </NavLink>
      <ul className={click ? "nav-menu active" : "nav-menu"}>
        <li>
          <NavLink to="/">Home</NavLink>
        </li>
        <li>
          <NavLink to="/about">About</NavLink>
        </li>
        <li>
          <NavLink to="/portfolio">Portfolio</NavLink>
        </li>
        <li>
          <NavLink to="/gallery">Gallery</NavLink>
        </li>
        <li>
          <NavLink to="/services">Services</NavLink>
        </li>

        <li>
          <NavLink to="/contact">Contact</NavLink>
        </li>
      </ul>
      <div className="hamburger" onClick={handleClick}>
        {click ? (
          <FaTimes className="text-[35px] text-red-500 hover:text-red-400 cursor-pointer" />
        ) : (
          <FaBars className="text-[30px] text-red-500 hover:text-red-400 cursor-pointer" />
        )}
      </div>
    </div>
  );
};

export default Navbar;
