import React, { useState } from "react";
import { data } from "../data/data";
import "./GalleryFilter.css";

const GalleryFilter = () => {
  const [products, setProducts] = useState(data);
  console.log(data);

  function filterCategory(category) {
    setProducts(
      data.filter((item) => {
        return item.category === category;
      })
    );
  }

  function filterPrice(price) {
    setProducts(
      data.filter((item) => {
        return item.price === price;
      })
    );
  }

  return (
    <>
      <div className="gallery-filter">
        <h2 className="section-heading">Portfolio</h2>
        <div className="gallery-container">
          <div className="gallery-panel">
            <div className="gallery-category-btns">
              <button onClick={() => setProducts(data)}>All</button>
              <button onClick={() => filterCategory("events-photography")}>
                Events Photographys
              </button>
              <button onClick={() => filterCategory("film-shooting")}>
                Film Shooting Photography
              </button>
              <button onClick={() => filterCategory("occasion-photography")}>
                Occasion Photography
              </button>
              <button onClick={() => filterCategory("award-photography")}>
                Award Winning Photography
              </button>
              <button onClick={() => filterCategory("fashion-photography")}>
                Fashion Photography
              </button>
            </div>
            {/* <div className="gallery-price-btns">
              <button onClick={() => filterPrice("$")}>$</button>
              <button onClick={() => filterPrice("$$")}>$$</button>
              <button onClick={() => filterPrice("$$$")}>$$$</button>
              <button onClick={() => filterPrice("$$$$")}>$$$$</button>
            </div> */}
          </div>
          <div className="gallery-content-section">
            {products.map((item, index) => (
              <div className="gallery-card" key={index}>
                <img src={item.image} alt="" />
                <div className="gallery-information">
                  <span className="gallery-title text-center">{item.name}</span>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default GalleryFilter;
