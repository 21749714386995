import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import "../routes/routes.css";
import GalleryFilter from "../components/GalleryFilter";

const Portfolio = () => {
  return (
    <div>
      <Navbar />
      <GalleryFilter />
      <Footer />
    </div>
  );
};

export default Portfolio;
