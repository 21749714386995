import React, { useState } from "react";
import "../components/Components.css";
import { FaTimes } from "react-icons/fa";

const GallerySection = () => {
  let data = [
    {
      id: 1,
      imgSrc:
        "https://images.pexels.com/photos/1185617/pexels-photo-1185617.jpeg?auto=compress&cs=tinysrgb&w=1600",
    },
    {
      id: 2,
      imgSrc:
        "https://images.pexels.com/photos/66134/pexels-photo-66134.jpeg?auto=compress&cs=tinysrgb&w=1600",
    },
    {
      id: 3,
      imgSrc:
        "https://images.pexels.com/photos/7541604/pexels-photo-7541604.jpeg?auto=compress&cs=tinysrgb&w=1600",
    },
    {
      id: 4,
      imgSrc:
        "https://images.pexels.com/photos/3221176/pexels-photo-3221176.jpeg?auto=compress&cs=tinysrgb&w=400",
    },
    {
      id: 5,
      imgSrc:
        "https://images.pexels.com/photos/2058070/pexels-photo-2058070.jpeg?auto=compress&cs=tinysrgb&w=400",
    },
    {
      id: 6,
      imgSrc:
        "https://images.pexels.com/photos/2119095/pexels-photo-2119095.jpeg?auto=compress&cs=tinysrgb&w=400",
    },
    {
      id: 7,
      imgSrc:
        "https://images.pexels.com/photos/12668929/pexels-photo-12668929.jpeg?auto=compress&cs=tinysrgb&w=400",
    },
    {
      id: 8,
      imgSrc:
        "https://images.pexels.com/photos/9036914/pexels-photo-9036914.jpeg?auto=compress&cs=tinysrgb&w=400",
    },
    {
      id: 9,
      imgSrc:
        "https://images.pexels.com/photos/1595241/pexels-photo-1595241.jpeg?auto=compress&cs=tinysrgb&w=1600",
    },
    {
      id: 10,
      imgSrc:
        "https://images.pexels.com/photos/9902654/pexels-photo-9902654.jpeg?auto=compress&cs=tinysrgb&w=600&lazy=load",
    },
    {
      id: 11,
      imgSrc:
        "https://images.pexels.com/photos/16364515/pexels-photo-16364515/free-photo-of-traditional-indian-wedding.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    },
    {
      id: 12,
      imgSrc:
        "https://images.pexels.com/photos/8497894/pexels-photo-8497894.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    },
    {
      id: 13,
      imgSrc:
        "https://images.pexels.com/photos/12851941/pexels-photo-12851941.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    },
    {
      id: 14,
      imgSrc:
        "https://images.pexels.com/photos/3992080/pexels-photo-3992080.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    },
    {
      id: 15,
      imgSrc:
        "https://images.pexels.com/photos/15698579/pexels-photo-15698579/free-photo-of-close-up-of-beautiful-bride-in-traditional-wedding-dress-and-jewelry.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    },
    {
      id: 16,
      imgSrc:
        "https://images.pexels.com/photos/1444442/pexels-photo-1444442.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    },
    {
      id: 17,
      imgSrc:
        "https://images.pexels.com/photos/16786426/pexels-photo-16786426/free-photo-of-bride-and-groom-wearing-traditional-clothing.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    },
    {
      id: 18,
      imgSrc:
        "https://images.pexels.com/photos/11812662/pexels-photo-11812662.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    },
    {
      id: 19,
      imgSrc:
        "https://images.pexels.com/photos/9157351/pexels-photo-9157351.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    },
    {
      id: 20,
      imgSrc:
        "https://images.pexels.com/photos/12992066/pexels-photo-12992066.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    },
    {
      id: 21,
      imgSrc:
        "https://images.pexels.com/photos/12962583/pexels-photo-12962583.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    },
  ];

  const [model, setModel] = useState(false);
  const [tempImgSrc, setTempImgSrc] = useState("");

  const getImg = (imgSrc) => {
    setTempImgSrc(imgSrc);
    setModel(true);
  };
  return (
    <>
      {/* <div className="body-container"> */}
      <div className="section-heading mt-6">Gallery</div>
      <div className={model ? "model open" : "model"}>
        <img src={tempImgSrc} alt="img" />
        <FaTimes
          className="gallery-close-btn"
          onClick={() => setModel(false)}
        />
      </div>
      <div className="gallery">
        {data.map((item, index) => (
          <div className="pics" key={index} onClick={() => getImg(item.imgSrc)}>
            <img src={item.imgSrc} style={{ width: "100%" }} alt="img" />
          </div>
        ))}
      </div>
      {/* </div> */}
    </>
  );
};

export default GallerySection;
