import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../components/Components.css";
import Slider from "react-slick";

const CategorySlider = () => {
  var settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 3000,
    initialSlide: 0,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 2,
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <>
      <div className="category-slider">
        <div className="body-container">
          <div className="category-heading">
            <h2 className="section-heading">Latest Album</h2>
          </div>
          <Slider {...settings} className="cards-slider">
            <div className="card-image">
              <img
                src="https://images.pexels.com/photos/14555276/pexels-photo-14555276.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                alt=""
              />
            </div>
            <div className="card-image">
              <img
                src="https://images.pexels.com/photos/8497894/pexels-photo-8497894.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                alt=""
              />
            </div>
            <div className="card-image">
              <img
                src="https://images.pexels.com/photos/9392460/pexels-photo-9392460.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                alt=""
              />
            </div>
            <div className="card-image">
              <img
                src="https://images.pexels.com/photos/13583827/pexels-photo-13583827.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                alt=""
              />
            </div>
            <div className="card-image">
              <img
                src="https://images.pexels.com/photos/3872626/pexels-photo-3872626.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                alt=""
              />
            </div>
            <div className="card-image">
              <img
                src="https://images.pexels.com/photos/1444442/pexels-photo-1444442.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                alt=""
              />
            </div>
            <div className="card-image">
              <img
                src="https://images.pexels.com/photos/16364515/pexels-photo-16364515/free-photo-of-traditional-indian-wedding.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                alt=""
              />
            </div>
            <div className="card-image">
              <img
                src="https://images.pexels.com/photos/18522950/pexels-photo-18522950/free-photo-of-laughing-bride-partaking-in-a-ceremony.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                alt=""
              />
            </div>
            <div className="card-image">
              <img
                src="https://images.pexels.com/photos/15698579/pexels-photo-15698579/free-photo-of-close-up-of-beautiful-bride-in-traditional-wedding-dress-and-jewelry.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                alt=""
              />
            </div>
          </Slider>
        </div>
      </div>
    </>
  );
};

export default CategorySlider;
