import React from "react";
import "../assets/Teams.css";
import { FaFacebook, FaLinkedin, FaPinterest, FaTwitter } from "react-icons/fa";

const Teams = () => {
  return (
    <>
      <div className="team-section">
        <div className="section-heading">Our Team</div>
        <div className="team-container">
          <div className="team-card">
            <img
              src="https://themewagon.github.io/alimie/img/bg-img/19.jpg"
              alt=""
            />
            <h3 className="team-name">Shivam Kumar</h3>
            <span>Web Developer</span>
            <div className="team-social-links">
              <FaFacebook />
              <FaTwitter />
              <FaLinkedin />
              <FaPinterest />
            </div>
          </div>
          <div className="team-card">
            <img
              src="https://themewagon.github.io/alimie/img/bg-img/20.jpg"
              alt=""
            />
            <h3 className="team-name">Milley cog</h3>
            <span>Web Designer</span>
            <div className="team-social-links">
              <FaFacebook />
              <FaTwitter />
              <FaLinkedin />
              <FaPinterest />
            </div>
          </div>
          <div className="team-card">
            <img
              src="https://themewagon.github.io/alimie/img/bg-img/21.jpg"
              alt=""
            />
            <h3 className="team-name">Sereya rog</h3>
            <span>Fashion Designer</span>
            <div className="team-social-links">
              <FaFacebook />
              <FaTwitter />
              <FaLinkedin />
              <FaPinterest />
            </div>
          </div>
          <div className="team-card">
            <img
              src="https://themewagon.github.io/alimie/img/bg-img/22.jpg"
              alt=""
            />
            <h3 className="team-name">Tom cyrus</h3>
            <span>Marketor</span>
            <div className="team-social-links">
              <FaFacebook />
              <FaTwitter />
              <FaLinkedin />
              <FaPinterest />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Teams;
