import React from "react";
import "../components/Components.css";
import { Link } from "react-router-dom";

const Blog = () => {
  const blogs = [
    {
      id: 1,
      title: "Events Photography",
      desc: "A website where a person writes regularly about topics that interest them, usually with photographs and links to other websites they find interesting.",
      coverImg:
        "https://images.pexels.com/photos/1185617/pexels-photo-1185617.jpeg?auto=compress&cs=tinysrgb&w=1600",
    },
    {
      id: 2,
      title: "Film Shooting Photography",
      desc: "Many wedding photographers still rely on their SLR camera. There are various reasons for this. Firstly, DSLRs have a good size that fits some photographer's hands better than a compact Sony Alpha 7R III",
      coverImg:
        "https://images.pexels.com/photos/66134/pexels-photo-66134.jpeg?auto=compress&cs=tinysrgb&w=1600",
    },
    {
      id: 3,
      title: "Special Occasion Photography",
      desc: "A blog is an informational website consisting of discrete, often informal diary-style text entries. Posts are typically displayed in reverse chronological order so that the most recent post appears first, at the top of the web page.",
      coverImg:
        "https://images.pexels.com/photos/2102765/pexels-photo-2102765.jpeg?auto=compress&cs=tinysrgb&w=1600",
    },
    {
      id: 4,
      title: "Award Winning Photography",
      desc: "The earliest instance of a commercial blog was on the first business to consumer Web site created in 1995 by Ty, Inc., which featured a blog in a section called. The entries were maintained by featured Beanie Babies that were voted for monthly by Web site visitors.",
      coverImg:
        "https://biosash.com/wp-content/uploads/2021/01/Biosash-Emerging-Awards.jpg",
    },
    {
      id: 5,
      title: "Fashion Photography",
      desc: "Another early blog was Wearable Wireless Webcam, an online shared diary of a person's personal life combining text, digital video, and digital pictures transmitted live from a wearable computer.",
      coverImg:
        "https://images.pexels.com/photos/1229414/pexels-photo-1229414.jpeg?auto=compress&cs=tinysrgb&w=1600",
    },
  ];

  return (
    <>
      <div className="blog-section">
        <div className="body-container">
          <div className="blog-heading">
            <h2 className="section-heading">Our Blogs</h2>
          </div>
          <div className="blog-container">
            {blogs.map((blog) => {
              return (
                <>
                  <Link key={blog} to={`/blogs/${blog.id}`}>
                    <div className="blog-card" key={blog.id}>
                      <img src={blog.coverImg} alt="coverImg" />
                      <span className="blog-content">
                        <h3>{blog.title}</h3>
                        <p>
                          {blog.desc.slice(0, 100)}.. <br />
                          <Link
                            className="text-[#ec1212fa] hover:text-[#fc5a5afa]"
                            to={`/blogs/${blog.id}`}
                          >
                            Read More
                          </Link>
                        </p>
                      </span>
                    </div>
                  </Link>
                </>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default Blog;
