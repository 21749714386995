import React from "react";
import { Link } from "react-router-dom";

const ServicesSection = () => {
  return (
    <>
      <section className="service-section">
        <div className="body-container">
          <div className="service-heading">
            <h2 className="section-heading">My Services</h2>
          </div>
          <div className="service-content">
            <Link to="/portfolio">
              <div className="service-card">
                <img
                  src="https://images.pexels.com/photos/1185617/pexels-photo-1185617.jpeg?auto=compress&cs=tinysrgb&w=1600"
                  alt=""
                />
                <h3>Events Photography</h3>
              </div>
            </Link>
            <Link to="/portfolio">
              <div className="service-card">
                <img
                  src="https://images.pexels.com/photos/66134/pexels-photo-66134.jpeg?auto=compress&cs=tinysrgb&w=1600"
                  alt=""
                />
                <h3>Film Shooting Photography</h3>
              </div>
            </Link>
            <Link to="/portfolio">
              <div className="service-card">
                <img
                  src="https://images.pexels.com/photos/2102765/pexels-photo-2102765.jpeg?auto=compress&cs=tinysrgb&w=1600"
                  alt=""
                />
                <h3>Special Occasion Photography</h3>
              </div>
            </Link>
            <Link to="/portfolio">
              <div className="service-card">
                <img
                  src="https://biosash.com/wp-content/uploads/2021/01/Biosash-Emerging-Awards.jpg"
                  alt=""
                />
                <h3>Award Winning Photography</h3>
              </div>
            </Link>
            <Link to="/portfolio">
              <div className="service-card">
                <img
                  src="https://images.pexels.com/photos/1229414/pexels-photo-1229414.jpeg?auto=compress&cs=tinysrgb&w=1600"
                  alt=""
                />
                <h3>Fashion Photography</h3>
              </div>
            </Link>
          </div>
        </div>
      </section>
    </>
  );
};

export default ServicesSection;
