import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import "./Contact.css";

const Contact = () => {
  return (
    <>
      <Navbar />
      <div className="contact-page">
        <div className="container">
          <h1 className="text-center text-4xl sm:text-6xl lg:text-7xl text-gray-500 font-bold mb-10 my-10">
            Contact Us
          </h1>

          <div className="contact-section-grid">
            {/* Forms */}
            <div className="form-container">
              <form action="https://formspree.io/f/xleybdga" method="POST">
                <label>Full Name</label>
                <input
                  type="text"
                  name="username"
                  placeholder="Full Name.."
                  autoComplete="off"
                />

                <label>Phone Number</label>
                <input
                  type="number"
                  name="number"
                  placeholder="Phone Number"
                  autoComplete="off"
                />

                <label>Email</label>
                <input
                  type="email"
                  name="email"
                  placeholder="Email"
                  autoComplete="off"
                  required
                />

                <label>Messege</label>
                <textarea
                  name="message"
                  placeholder="Write your message.."
                  autoComplete="off"
                  required
                  style={{ height: "200px" }}
                ></textarea>

                <input type="submit" value="Submit" />
              </form>
            </div>
            {/* Map */}
            <div>
              <iFrame
                style={{ width: "100%", height: "600px" }}
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14486.418427412837!2d85.0290699!3d24.8089892!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39f329f767d75da3%3A0x6b00fab9d12c277d!2sskhubs!5e0!3m2!1sen!2sin!4v1700908660476!5m2!1sen!2sin"
              ></iFrame>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Contact;
