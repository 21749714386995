import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import ServicesSection from "../components/ServicesSection";

import ServicesBox from "../components/ServicesBox";
import TestimonialSection from "../components/TestimonialSection";

const Services = () => {
  return (
    <div>
      <Navbar />
      <ServicesSection />
      <ServicesBox />
      <TestimonialSection />
      <Footer />
    </div>
  );
};

export default Services;
