import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../assets/TestimonialSection.css";
import Slider from "react-slick";

const TestimonialSection = () => {
  var settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 3000,
    initialSlide: 0,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 2,
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <>
      <div className="testimonial-slider">
        <div className="body-container">
          <div className="testimonial-heading">
            <h2 className="section-heading">Testimonial</h2>
          </div>
          <Slider {...settings} className="cards-slider">
            <div className="testimonial-card">
              <img
                src="https://images.pexels.com/photos/2379004/pexels-photo-2379004.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                alt=""
              />
              <div className="testimonial-feedback">
                <h3 className="feedback-name">Shivam Kumar</h3>
                <p>
                  Easily the best experience with a photographer to date!
                  Infinite memories is super personable and very talented at his
                  craft. He made sure everyone was comfortable and enjoying the
                  experience. Thanks
                </p>
                <span className="review-icons">⭐⭐⭐⭐⭐</span>
              </div>
            </div>
            <div className="testimonial-card">
              <img
                src="https://images.pexels.com/photos/91227/pexels-photo-91227.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                alt=""
              />
              <div className="testimonial-feedback">
                <h3 className="feedback-name">Rohit Kumar</h3>
                <p>
                  Very incredible editing of photos and videos. Everything is on
                  ontime and communication is at a best level follow and
                  everything. All photos and videos are received on time. The
                  Photographs in the gallery are also extraordinarily.
                </p>
                <span className="review-icons">⭐⭐⭐⭐</span>
              </div>
            </div>
            <div className="testimonial-card">
              <img
                src="https://images.pexels.com/photos/6590847/pexels-photo-6590847.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                alt=""
              />
              <div className="testimonial-feedback">
                <h3 className="feedback-name">Rakesh Kumar</h3>
                <p>
                  Photographer convert you best movement into memories. Just
                  amazing pictures everyone has different talents but
                  photographer meant to be captured your talent, so stay with
                  those who know value of your emotions ❤✌
                </p>
                <span className="review-icons">⭐⭐⭐⭐⭐</span>
              </div>
            </div>
            <div className="testimonial-card">
              <img
                src="https://images.pexels.com/photos/2010877/pexels-photo-2010877.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                alt=""
              />
              <div className="testimonial-feedback">
                <h3 className="feedback-name">Shreya Kumari</h3>
                <p>
                  Wedding photo creators is truly talented and all around
                  genuinely nice, and amicable person. we found him and were
                  able to secure him for our big day! His talent is coupled by
                  his extraordinary ability to capture the moment.
                </p>
                <span className="review-icons">⭐⭐⭐⭐⭐</span>
              </div>
            </div>
            <div className="testimonial-card">
              <img
                src="https://images.pexels.com/photos/8206767/pexels-photo-8206767.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                alt=""
              />
              <div className="testimonial-feedback">
                <h3 className="feedback-name">Anil Kumar</h3>
                <p>
                  He truly made our wedding memorable by not only his presence
                  but also the result of his beautiful work. Thank you Wedding
                  photo creators. I will forever be grateful that you were there
                  on that day.
                </p>
                <span className="review-icons">⭐⭐⭐⭐⭐</span>
              </div>
            </div>
            <div className="testimonial-card">
              <img
                src="https://images.pexels.com/photos/13544084/pexels-photo-13544084.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                alt=""
              />
              <div className="testimonial-feedback">
                <h3 className="feedback-name">Deepak Kumar</h3>
                <p>
                  Candid Wedding Photography, Cinematic Wedding Photography,
                  beautiful wedding album—all you can get here. This site is
                  fabulous. One of my friends suggested me to look over, and it
                  made me jump with excitement.
                </p>
                <span className="review-icons">⭐⭐⭐⭐⭐</span>
              </div>
            </div>
            <div className="testimonial-card">
              <img
                src="https://images.pexels.com/photos/1362724/pexels-photo-1362724.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                alt=""
              />
              <div className="testimonial-feedback">
                <h3 className="feedback-name">Rabina Kumari</h3>
                <p>
                  Those pictures gave me Goosebumps. I decide to contact them
                  for every marriage that will be taken place in our family. I
                  have seen them working smoothly in lots of difficulties. They
                  deserve thumbs up.
                </p>
                <span className="review-icons">⭐⭐⭐⭐⭐</span>
              </div>
            </div>
          </Slider>
        </div>
      </div>
    </>
  );
};

export default TestimonialSection;
